/*
this is used for:
- Algolia Taxon Products CMS Module
- PDP Recommendation Product Carousels
*/
import React from 'react';
import PropTypes from 'prop-types';
// TODO: remove Flickity
// import dynamic from 'next/dynamic';
import Flickity from 'react-flickity-component';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useDomLoaded } from '../../utils/hooks';

import MobileProductCarousel from '../../atoms/mobile-swiper-product-carousel';

// TODO: Re-enable this dynamic import, removed for causing a
// build error. You can't feed a loading dynamic import to styled()
// const Flickity = process.env.NODE_ENV === 'test'
//   ? require('react-flickity-component')
//   : dynamic(
//     () => import('react-flickity-component'),
//     { ssr: false }
//   );

const CHILD_CLASS = 'carousel-cell';

const carouselBaseStyles = css`
  position: relative;

  .${CHILD_CLASS} {
    height: auto;
    position: relative;
    width: 100%;
  }
`;

const carouselArrowStyles = (theme, hasUpToFiveProducts) => css`
  .flickity-prev-next-button {
    background: transparent;
    border-radius: 100%; 
    border: 1px solid ${theme.color.brand};
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 3rem;
    line-height: 0;
    opacity: .75;
    outline: none;
    padding: 0;
    position: absolute;
    stroke: ${theme.color.brand};
    top: 50%;
    transform: translate(0, -50%);
    transition: opacity ${theme.animation.default} ${theme.animation.easeOutQuad};
    width: 3rem;
    :hover {
      background: transparent;
      color: transparent;
      opacity: 1;
      outline: none;
    }
    svg {
      fill: ${theme.color.brand};
      height: 1rem;
      stroke-width: 2;
      width: 1rem;
    }
    &.previous {
      left: -3rem;
    }
    &.next {
      right: -3rem;
      left: auto;
    }
    :disabled {
      cursor: not-allowed;
      opacity: .25;
    }

    ${hasUpToFiveProducts && css`
      @media (min-width: ${theme.breakpoint.medium}) {
        display: none;
      }
    `}

    // remove arrows on mobile
    @media screen and (max-width: ${theme.breakpoint.medium}) {
      display: none;
    }
  }
`;

const carouselCellStyles = ({
  type,
  theme,
  hasUpToFiveProducts
}) => {
  const styles = css`
    ${carouselBaseStyles}
    ${carouselArrowStyles(theme, hasUpToFiveProducts)}
  `;


  const sharedDesktopStyles = css`
    margin: 3rem 1rem;
    position: relative;
  `;

  // to display 2.5 multiple product cards on Mobile
  const mobileCardStyles = css`
    @media screen and (max-width: ${theme.breakpoint.small}) {
      width: 40%;
      margin: 1rem 0rem;
      padding: 0 0.4rem;
    }
  `;

  if (type === 'product-card') {
    return css`
      ${styles}
      ${sharedDesktopStyles}

      .flickity-viewport {
        overflow: hidden;
      }

      // for mobile
      @media screen and (max-width: ${theme.breakpoint.small}) {
        margin: 0rem -1.5rem;
      }

      .${CHILD_CLASS} {
        // for tablet
        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: 40%;
        }

        // for desktop
        @media screen and (min-width: ${theme.breakpoint.medium}) {
          width: 20%;
          padding: 0 1.8rem;
        }

        ${mobileCardStyles}
      }
    `;
  }

  if (type === 'taxon-products') {
    return css`
      ${styles}
      ${sharedDesktopStyles}

      .flickity-viewport {
        overflow: hidden;
      }

      // for mobile
      @media screen and (max-width: ${theme.breakpoint.small}) {
        margin: 0rem -3rem;
      }

      .${CHILD_CLASS} {
        // for tablet
        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: 40%;
        }

        // for desktop
        @media screen and (min-width: ${theme.breakpoint.medium}) {
          padding: 0 1.8rem;
          width: 25%;
        }

        // for large desktops
        @media screen and (min-width: ${theme.breakpoint.large}) {
          width: 20%;
        }

        ${mobileCardStyles}
      }
    `;
  }

  return styles;
};

const StyledFlickity = styled(Flickity)`
  ${carouselCellStyles}

  @media (max-width: ${(props) => props.theme.breakpoint.medium}) {
    display: none;
  }
`;

const FlickityPlaceHolder = styled.div`
  display: flex;
  overflow: hidden;

  .carousel-cell:first-of-type {
    margin-left: 1.5rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
      margin-left: 0;
    }
  }

  > * {
    flex: 0 0 auto;
  }
  ${carouselCellStyles}
`;

const Carousel = (props) => {
  const isDomReady = useDomLoaded();

  const defaultOptions = {
    arrowShape: 'M 46.19 3.452 L 53.333 10.119 L 18.571 45.357 L 100 45.357 L 100 54.881 L 18.571 54.881 L 53.571 90.119 L 47.381 96.548 L 0 50.357 L 46.19 3.452 Z'
  };

  const options = {
    ...defaultOptions,
    ...props.options
  };

  const children = React.Children.map(props.children, (child) => {
    const childProps = {
      ...child.props,
      className: `${CHILD_CLASS} ${child.props.className ?? ''}`
    };

    return React.cloneElement(child, childProps);
  });

  return (
    <>
      {
        isDomReady
          ? (
            <>
              <StyledFlickity
                className={props.className}
                disableImagesLoaded={props.disableImagesLoaded}
                elementType={props.elementType}
                flickityRef={props.flickityRef}
                options={options}
                reloadOnUpdate={props.reloadOnUpdate}
                static={props.static}
                type={props.type}
                hasUpToFiveProducts={props.hasUpToFiveProducts}
              >
                {children}
              </StyledFlickity>
              <MobileProductCarousel type={props.type} loop={props.loop}>
                {props.children}
              </MobileProductCarousel>
            </>
          )
          : (
            // TODO: TEC-8672 can we remove this placeholder after removing Flickity?
            <FlickityPlaceHolder
              type={props.type}
            >
              {children}
            </FlickityPlaceHolder>
          )
      }
    </>
  );
};

Carousel.defaultProps = {
  children: null,
  className: '',
  disableImagesLoaded: false,
  elementType: 'div',
  flickityRef: () => { },
  options: {},
  reloadOnUpdate: false,
  static: false,
  type: undefined,
  hasUpToFiveProducts: false,
  loop: false
};

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disableImagesLoaded: PropTypes.bool,
  elementType: PropTypes.string,
  flickityRef: PropTypes.func,
  options: PropTypes.object,
  reloadOnUpdate: PropTypes.bool,
  static: PropTypes.bool,
  hasUpToFiveProducts: PropTypes.bool,
  type: PropTypes.oneOf([
    undefined,
    'product-card',
    'taxon-products'
  ]),
  loop: PropTypes.bool
};

Carousel.whyDidYouRender = true;

export default Carousel;
