/*
this is used for:
- Algolia Taxon Products CMS Module
- PDP Recommendation Product Carousels
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';

// swiper core styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styled from '@emotion/styled';

const SwiperWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'title' })`
  margin: 0 -3rem;

  @media (min-width: ${(props) => props.theme.breakpoint.medium}) {
    display: none;
  }
`;

const MobileProductCarousel = ({ children, type, loop }) => (
  <SwiperWrapper type={type}>
    <Swiper loop={loop} spaceBetween={8} slidesPerView={2.5} slidesOffsetBefore={15} slidesOffsetAfter={15}>
      {children.length > 0 &&
        children.map((child) => <SwiperSlide key={`product-carousel-${child.key}`}>{child}</SwiperSlide>)}
    </Swiper>
  </SwiperWrapper>
);

MobileProductCarousel.defaultProps = {
  children: null,
  type: undefined,
  loop: false
};

MobileProductCarousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.oneOf([undefined, 'product-card', 'taxon-products']),
  loop: PropTypes.bool
};

MobileProductCarousel.whyDidYouRender = true;

export default MobileProductCarousel;
